import { HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfiguration } from '../api-configuration';
import { BaseService } from '../base-service';
import { GatewayInfoDto } from './../models/gateway/gateway-info.dto';

@Injectable({ providedIn: 'root' })
export class GatewayService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  getGatewayInfo() {
    return this.http.get<GatewayInfoDto[]>(
      `${this.rootUrl}/sign/user/gateways`
    );
  }

  createGateway(gatewayInfo: GatewayInfoDto) {
    return this.http.post(`${this.rootUrl}/sign/gateways`, gatewayInfo);
  }

  deleteGateway(gatewayInfoId: number) {
    return this.http.delete(`${this.rootUrl}/sign/gateways/${gatewayInfoId}`);
  }

  editGateway(gatewayInfo: GatewayInfoDto) {
    return this.http.put(`${this.rootUrl}/sign/gateways/${gatewayInfo.gatewayInfoId}`, gatewayInfo);
  }
}
