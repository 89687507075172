import { DeviceService } from './services/device.service';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ApiConfiguration } from './api-configuration';

import { ApiService } from './services/api.service';
import { FleetService } from './services/fleet.service';
import { VehicleService } from './services/vehicle.service';
import { AlertService } from './services/alert.service';
import { UserService } from './services/user.service';
/* @author Eric Su */
import { AuthenticationService } from './services/authentication.service';

/**
 * Module that provides instances for all API services
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ApiConfiguration,
   ApiService,
   FleetService,
   VehicleService,
   AlertService,
   UserService,
   DeviceService,
   // @author Eric Su
   AuthenticationService
  ],
})
export class ApiModule { }
