import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
/* tslint:disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ApiConfiguration } from '../api-configuration';
import { BaseService } from '../base-service';
import { ApiResponse } from '../models/api-response';
import { DeviceTypeDto } from '../models/device/device-type.dto';
import { ProductType } from '../models/device/product-type';


/**
 * Access to fleet information
 */
@Injectable()
export class DeviceService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient,
  ) {
    super(config, http);
  }

  getProductTypes() {
    return this.http.get<ProductType[]>(`${this.rootUrl}/iotp/product-types`);
  }

  getDeviceTypes() {
    return this.http.get<DeviceTypeDto[]>(`${this.rootUrl}/iotp/device-types?productName=SIGN`);
  }
}
