export class GatewayBoardDto {
  vehicleNumber: string;
  fleetName: string;
  deviceType: string;
  deviceTypeId: number;
  deviceNumber: string;
  vin: string;
  status: string;
  gatewayDescription: string | null;
  createdDate: Date;
  fleetId: number;
  gatewayInfoId: number;
}
