import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FleetDto } from 'app/api/models/fleet/fleet.dto';
import { GatewayBoardDto } from 'app/api/models/gateway/gateway-board.dto';
import { FleetService, VehicleService } from 'app/api/services';
import { GatewayService } from 'app/api/services/gateway.service';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { GatewayInfoDto } from 'app/api/models/gateway/gateway-info.dto';
import { VehicleDto } from 'app/api/models/vehicle/vehicle.dto';
import { DeviceTypeDto } from 'app/api/models/device/device-type.dto';
import { DeviceService } from 'app/api/services/device.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-gateway',
  templateUrl: './gateway.component.html',
  styleUrls: ['./gateway.component.scss']
})
export class GatewayComponent implements OnInit, OnDestroy {
  iotpDeviceUrl = environment.iotpDeviceUrl;
  spinning = false;
  inputForm = new FormGroup({
    fleetId: new FormControl(null),
    vehicleId: new FormControl(null),
    deviceTypeId: new FormControl(null),
    deviceNumber: new FormControl(null),
    vin: new FormControl(null),
    gatewayDescription: new FormControl(null),
    gatewayInfoId: new FormControl(null)
  });
  filteredGatewaysBoardDto$: Observable<GatewayBoardDto[]>;
  fleets: FleetDto[];
  vehicles: VehicleDto[];
  filteredVehicles: VehicleDto[];
  deviceTypes: DeviceTypeDto[];

  pageList: Array<number> = [5, 10, 20];
  selectedFleet;
  chooseFleet;
  pageSize = 10;
  constructor(
    private modalService: NgbModal,
    private readonly gatewayService: GatewayService,
    private readonly fleetService: FleetService,
    private readonly vehicleService: VehicleService,
    private readonly deviceService: DeviceService
  ) {}

  private gatewaysBoardDto$: Observable<GatewayBoardDto[]>;
  private currentSearchTerm: string;

  ngOnInit() {
    this.getGatewayList();
    this.filterBySearchTerm();
    // this.setDeviceTypes();
  }

  ngOnDestroy() {}

  getDeviceType(deviceTypeId: number) {
    const matchedDevice = this.deviceTypes.find(
      (item) => item.deviceTypeId === deviceTypeId
    );
    // console.log('matchedDevice :>> ', matchedDevice);
    return matchedDevice ? matchedDevice.deviceName : '';
  }

  setDeviceTypes() {
    return this.deviceService
      .getDeviceTypes()
      .pipe(tap((types) => (this.deviceTypes = types)));
    // this.deviceService
    //   .getDeviceTypes()
    //   .subscribe((types) => (this.deviceTypes = types));
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    this.currentSearchTerm = val;
    this.filterBySearchTerm();
  }

  filterByFleet(fleetId) {
    this.chooseFleet = parseInt(fleetId, 10);
    this.filterGatewayByFleetId(this.chooseFleet);
  }

  changePageSize(pageSize: string) {
    this.pageSize = +pageSize;
  }

  openGatewayDescription(row) {
    alert(row['gatewayDescription']);
  }

  onFleetSelectChange(fleetId) {
    this.filteredVehicles = this.vehicles.filter(
      (v) => v.fleetId === parseInt(fleetId, 10)
    );
    if (this.filteredVehicles.length > 0) {
      this.inputForm.controls['vehicleId'].setValue(
        this.filteredVehicles[0].vehicleId
      );
    } else {
      this.inputForm.controls['vehicleId'].setValue(null);
    }
  }

  openAddGatewayModal(content) {
    this.inputForm.reset();
    if (this.fleets.length > 0) {
      this.inputForm.controls['fleetId'].setValue(this.fleets[0].fleetId);
    }
    this.filteredVehicles = this.vehicles.filter(
      (v) => v.fleetId === this.fleets[0].fleetId
    );
    if (this.filteredVehicles.length > 0) {
      this.inputForm.controls['vehicleId'].setValue(
        this.filteredVehicles[0].vehicleId
      );
    }
    this.modalService.open(content, {
      centered: true,
      size: 'lg'
    });
  }

  createGateway() {
    if (!this.inputForm.valid) {
      alert('Missing field(s)');
      return;
    } else {
      const newGateWayInfo = new GatewayInfoDto();
      newGateWayInfo.fleetId = parseInt(
        this.inputForm.get('fleetId').value,
        10
      );
      newGateWayInfo.companyId = this.fleets
        .filter((f) => f.fleetId === newGateWayInfo.fleetId)
        .map((f) => {
          return f.companyId;
        })[0];
      newGateWayInfo.vehicleId = parseInt(
        this.inputForm.get('vehicleId').value,
        10
      );
      newGateWayInfo.deviceNumber = this.inputForm.get('deviceNumber').value;
      newGateWayInfo.deviceTypeId = +this.inputForm.get('deviceTypeId').value;
      newGateWayInfo.vin = this.vehicles
        .filter((v) => v.vehicleId === newGateWayInfo.vehicleId)
        .map((v) => {
          return v.vin;
        })[0];
      newGateWayInfo.gatewayDescription =
        this.inputForm.get('gatewayDescription').value;
      this.gatewayService.createGateway(newGateWayInfo).subscribe(
        (response) => {
          this.modalService.dismissAll();
          this.filterGatewayByFleetId(newGateWayInfo.fleetId);
        },
        (error) => {
          console.log(error);
          if (error.status === 409) {
            alert('Database Conflict. Duplicate vehicle number.');
          }
        }
      );
    }
  }

  openEditGatewayModal(content, row) {
    this.modalService.open(content, {
      centered: true,
      size: 'lg'
    });
    this.inputForm.patchValue({
      deviceNumber: row.deviceNumber,
      gatewayDescription: row.gatewayDescription,
      gatewayInfoId: row.gatewayInfoId
    });
    this.inputForm.controls['fleetId'].setValue(row.fleetId);
    this.filteredVehicles = this.vehicles.filter(
      (v) => v.fleetId === row.fleetId
    );
    const vehicleId = this.filteredVehicles
      .filter((v) => v.vehicleNumber === row.vehicleNumber)
      .map((v) => {
        return v.vehicleId;
      })[0];
    this.inputForm.controls['vehicleId'].setValue(vehicleId);
    this.inputForm.controls['deviceTypeId'].setValue(row.deviceTypeId);
  }

  updateGateway() {
    if (!this.inputForm.valid) {
      alert('Missing field(s)');
      return;
    } else {
      const updatedGateWayInfo = new GatewayInfoDto();
      updatedGateWayInfo.fleetId = parseInt(
        this.inputForm.get('fleetId').value,
        10
      );
      updatedGateWayInfo.companyId = this.fleets
        .filter((f) => f.fleetId === updatedGateWayInfo.fleetId)
        .map((f) => {
          return f.companyId;
        })[0];
      updatedGateWayInfo.vehicleId = parseInt(
        this.inputForm.get('vehicleId').value,
        10
      );
      updatedGateWayInfo.vin = this.vehicles
        .filter((v) => v.vehicleId === updatedGateWayInfo.vehicleId)
        .map((v) => {
          return v.vin;
        })[0];
      updatedGateWayInfo.deviceNumber =
        this.inputForm.get('deviceNumber').value;
      updatedGateWayInfo.deviceTypeId =
        +this.inputForm.get('deviceTypeId').value;
      updatedGateWayInfo.gatewayDescription =
        this.inputForm.get('gatewayDescription').value;
      updatedGateWayInfo.gatewayInfoId =
        this.inputForm.get('gatewayInfoId').value;
      this.gatewayService.editGateway(updatedGateWayInfo).subscribe(
        (response) => {
          this.modalService.dismissAll();
          this.filterGatewayByFleetId(updatedGateWayInfo.fleetId);
        },
        (error) => {
          console.log(error);
          alert('Database Conflict. Duplicate vehicle number.');
        }
      );
    }
  }

  deleteGateway(row) {
    const vehicleNumber = row['vehicleNumber'];
    const gatewayInfoId = parseInt(row['gatewayInfoId'], 10);
    if (confirm(`Are you sure you want to delete Gateway ${vehicleNumber}?`)) {
      this.gatewayService.deleteGateway(gatewayInfoId).subscribe(
        () => {
          alert(`Gateway ${vehicleNumber} successfully deleted`);
          this.filterGatewayByFleetId(row['fleetId']);
        },
        (error) => {
          alert('Gateway deleted failed');
        }
      );
    }
  }

  private getGatewayList() {
    this.gatewaysBoardDto$ = this.setDeviceTypes().pipe(
      switchMap(() => {
        return combineLatest(
          this.gatewayService.getGatewayInfo(),
          this.fleetService.getUserOwnedFleets(),
          this.vehicleService.getVehicles(),
          (gatewaysInfo, fleets, vehicles) => {
            this.fleets = fleets;
            if (this.fleets.length > 0) {
              this.selectedFleet = this.fleets[0].fleetId;
            }
            this.vehicles = vehicles;
            const gatewaysBoardDto: GatewayBoardDto[] = [];
            for (const gatewayInfo of gatewaysInfo) {
              let fleet: FleetDto;
              const vehicle = vehicles.find(
                (v) => v.vehicleId === gatewayInfo.vehicleId
              );
              // console.log('vehicle :>> ', vehicle);
              if (!vehicle) {
                continue;
              }
              fleet = fleets.find((f) => f.fleetId === vehicle.fleetId);
              // console.log('fleet :>> ', fleet);
              // console.log('gatewayInfo :>> ', gatewayInfo.deviceTypeId);
              const gatewayBoardDto = new GatewayBoardDto();
              gatewayBoardDto.vehicleNumber = vehicle.vehicleNumber;
              gatewayBoardDto.fleetName = fleet ? fleet.fleetName : '';
              gatewayBoardDto.deviceType = this.getDeviceType(
                gatewayInfo.deviceTypeId
              );
              gatewayBoardDto.deviceTypeId = gatewayInfo.deviceTypeId;
              gatewayBoardDto.deviceNumber = gatewayInfo.deviceNumber;
              gatewayBoardDto.vin = vehicle.vin;
              gatewayBoardDto.status =
                gatewayInfo.statusTypeId === 1 ? 'Active' : 'InActive';
              gatewayBoardDto.gatewayDescription =
                gatewayInfo.gatewayDescription;
              gatewayBoardDto.createdDate = gatewayInfo.createdDate;
              gatewayBoardDto.fleetId = fleet ? fleet.fleetId : null;
              gatewayBoardDto.gatewayInfoId = gatewayInfo.gatewayInfoId;
              gatewaysBoardDto.push(gatewayBoardDto);
            }
            return gatewaysBoardDto;
          }
        );
      })
    );
  }

  private filterGatewayByFleetId(fleetId) {
    this.spinning = true;
    this.filteredGatewaysBoardDto$ = this.gatewaysBoardDto$.pipe(
      map((gatewaysBoardDto) =>
        gatewaysBoardDto.filter((g) => g.fleetId === fleetId)
      ),
      tap(() => (this.spinning = false))
    );
  }

  private filterBySearchTerm() {
    this.spinning = true;
    this.filteredGatewaysBoardDto$ = this.gatewaysBoardDto$.pipe(
      map((gatewaysBoardDto) =>
        gatewaysBoardDto.filter(
          (x) =>
            (x.vehicleNumber.toLowerCase().indexOf(this.currentSearchTerm) !==
              -1 ||
              this.currentSearchTerm === undefined) &&
            ((this.chooseFleet === undefined &&
              x.fleetId === this.selectedFleet) ||
              x.fleetId === this.chooseFleet)
        )
      ),
      tap(() => (this.spinning = false))
    );
  }
}
